import React, { Component }  from "react"
import PropTypes from "prop-types"
import Settings from "classes/Settings"
import styled, {css} from 'styled-components'
// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import Button from "components/Button/Button"
import NumberSlider from "components/NumberSlider/NumberSlider"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import SwipeCards, { SwipeCard } from "components/SwipeCards/SwipeCards"

// HELPERS
import api, * as apiHelper from "helpers/api"

import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

// OTHER
import moment from "moment"

export default class ReportTimeWindow extends Component {
    static propTypes = {
        fetchDataAsync: PropTypes.func.isRequired,
    }

    state = {
        visible: false,
        activeDate: null,
        animateNextDate: null,
        animateNextData: null,
        autoOpened: true
    }

    close = () => {
        return new Promise(resolve => {
            this.setState({ visible: false }, () => setTimeout(() => {
                this.props.setTimeReportData(null)
                this.props.onClose()
                resolve()
            }, 300))
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.reportDates && nextProps.reportDates.length > 0) {
            this.setState(prevState => ({
                lastDate:prevState.activeDate,
                lastProject:prevState.activeProject,
                visible: true,
                activeDate: nextProps.reportDates[nextProps.reportDates.length - 1].date,
                animateNextDate: null,
                animateNextData: null,
                firstOpen: !!(this.state.autoOpened && !nextProps.reportDates[nextProps.reportDates.length - 1].isCellClick)
            }), this.setState({ autoOpened: false }))
        } else {
            this.setState(prevState => ({
                visible: false,
                activeDate: null,
                animateNextDate: null,
                animateNextData: null
            }))
        }
      
    }

    render() {
        const { visible, animateNextDate, animateNextData, activeDate, lastDate, firstOpen } = this.state
        const { reportDates, type, className } = this.props

        if (!reportDates) return null

        const classes = utils.createClassName(className, {
            "ReportTimeWindow": true,
            "visible": visible,
            "animating": !!animateNextDate || !!animateNextData
        })


    /*  avatarPath: "/projects/avatars/resource_Timr20191022231.png"
        color: ""
        date: "2020-09-11"
        lastReportedHour: 1
        projectId: 22
        resourceId: 101
        salaryMultiplier: 100
        shadow: 0
        textColor: "#FFFFFF"
        title: "Timr"
        type: "resources"
    */
        const dates = reportDates.map((dateData, dateIndex) => {
            const isActiveDate = activeDate === dateData.date

            const cardsClasses = utils.createClassName("ReportTimeWindow-cards", {
                "active": isActiveDate,
                "animate-in": animateNextDate === dateData.date
            })

            return (
                <div key={`ReportTimeWindow-date-${dateData.date}-${dateIndex}`} className={cardsClasses}>
                    {dateData.dataset.map((data, dataIndex, dataset) => {
                        const isActiveData = dataIndex === dataset.length - 1
                        const isNextData = dataIndex === dataset.length - 2
                        data.type = type

                        return (
                            <Card
                                key={`ReportTimeWindow-card-${dateData.date}-${dataIndex}`}
                                context={data}
                                actions={{ onMomentum: _onCardMomentum.bind(this, data) }}
                                spread={dateData.spread}
                                isActiveDate={isActiveDate}
                                isActive={isActiveData}
                                animateIn={isActiveDate && isNextData && animateNextData === data.date}
                                onReportTime={_onReportTime.bind(this, data)}
                                onEscape={this.close}
                                lastDate={lastDate}
                                firstOpen={firstOpen}
                            />
                        )
                    })}
                </div>
            )
        })

        return (
            <div className={ classes }>
                <div className="ReportTimeWindow-overlay" onClick={ this.close } />
                <SwipeCards>
                    <div className="ReportTimeWindow-dates">{ dates }</div>
                </SwipeCards>
            </div>
        )
    }
}


// PRIVATE COMPONENTS
class Card extends React.PureComponent {
    state = {
        animateIn: false
    }

    componentDidMount() {
        document.documentElement.classList.add('disableScroll')

        if (this.props.animateIn) this._animateIn()
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('disableScroll')
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.animateIn) this._animateIn()
    }

    render() {
        const { context, className, actions, isActiveDate, isActive, onReportTime, onEscape, spread, lastDate, firstOpen } = this.props
        const classes = utils.createClassName(className, {
            "ReportTimeWindow-card": true,
            "active": isActive,
            "animate-in": this.state.animateIn && !isActive
        })

        if (!isActive || !isActiveDate) return (
            <Div className={classes}>
                <ActivityIndicator busy={true} />
            </Div>)
        else {
            return (
                <SwipeCard
                    friction={0.1}
                    threshold={0.1}
                    onMomentum={actions.onMomentum}
                    onOutOfBounds={actions.onOutOfBounds}
                    hours={this.state.hours}
                >
                    <Div className={classes}>
                        <CardContent {...context}
                            onReportTime={onReportTime}
                            onEscape={onEscape}
                            onHourChange={(hours) => _onHourChange(this, hours)}
                            spread={spread}
                            lastDate={lastDate}
                            firstOpen={firstOpen}
                        />
                    </Div>
                </SwipeCard>
            )
        }
    }

    // Internal methods
    _animateIn = () => this.setState({ animateIn: true })
}

class CardContent extends React.PureComponent {
    static propTypes = {
       onReportTime: PropTypes.func.isRequired
    }

    state = {
        hours: this.props.hour,
        pulseDate: false
    }
    
    NumberSliderRef = React.createRef()
    cardContent = React.createRef()

    onReportTime = (e) => {
        if (this.state.busy) return
        this.props.onReportTime(this.state.hours)
        this.setState({busy: true})
    }
    onEscape = (e) => this.props.onEscape()

    componentDidMount() {
        const { lastDate, date, firstOpen } = this.props

        this.checkDatePulse(lastDate, date, firstOpen)

        const { lastReportedHour, onHourChange } = this.props

        if (!this.state.hours) {
            this.setState({ hours: lastReportedHour })
            onHourChange(lastReportedHour)
        } else {
            onHourChange(this.state.hours)
        }

        this.cardContent.current.focus()
    }

    render() {
        const { date, title, avatarPath, spread, onHourChange, lastReportedHour } = this.props
        let realSpread = spread + this.state.hours - lastReportedHour

        const pulseClasses = utils.createClassName("ReportTimeWindow-card.content", {
            "pulseDate": this.state.pulseDate
        })

        return (
            <div ref={this.cardContent} className="content" tabIndex='0' onKeyDown={this.NumberSliderRef.current && this.NumberSliderRef.current._onKeyPress}>
                <div className="context">
                    <Div className="avatar" style={{ backgroundImage: `url(${apiHelper.getAssetsURIForPath(avatarPath)})` }}/>
                    <div id ="title" className={`name ${pulseClasses}`}>{title}</div>
                </div>
                <Div className="date">
                    <span id='current-date'className={pulseClasses}>{moment(date, 'YYYY-MM-DD').format('dddd DD')}</span>
                    <span>{moment(date, 'YYYY-MM-DD').format('-MMM-YYYY')}</span>
                </Div>
                <NumberSlider
                    ref={this.NumberSliderRef}
                    lastReportedHour={lastReportedHour}
                    onChange={(hours) => { _onHourChange(this, hours); onHourChange(hours) }}
                    onEnter={this.onReportTime}
                    onEscape={this.onEscape}
                />
                <StackLayout className="buttons-container" orientation="horizontal">
                    <Button
                        label={i18n("timereport", "report_time")}
                        type="submit"
                        appearance="primary"
                        filled
                        onClick={this.onReportTime}
                    />
                </StackLayout>
                <div className="spread" style={{color: Settings.getGlobalColor("label")}}> 
                    {!isNaN(realSpread) && realSpread && <span>{realSpread > 0 && '+'}{realSpread + i18n("general", "short_hours")}</span>}
                    <div className="tooltip-wrapper">
                        <p className="tooltip-item">
                            {Math.abs(realSpread)} {Math.abs(realSpread) === 1 ? i18n("timereport", "hour") : i18n("timereport", "hours")} 
                            {realSpread >= 0 ? ` ${i18n("timereport", "above")} ` : ` ${i18n("timereport", "below")} `}
                            {i18n("timereport", "tooltip1")}{'\n'}{i18n("timereport", "tooltip2")}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    checkDatePulse(lastDate, currentDate, firstOpen) {
        // does not account for holidays
        let workdays = utils.getWorkdaysBetween(lastDate, currentDate)

        // check if lastDate is a workday
        let fromWorkday = !!utils.getWorkdaysBetween(lastDate, lastDate)
        // getWorkdaysBetween includes both full dates, ex: monday -> tuesday = 2 | sunday -> monday = 1
        // expected workdays is different if lastDate was a holiday or not
        let expectedWorkdays = fromWorkday ? 2 : 1

        // pulses date if a non weekend was skipped or if it auto opened on another date than today
        if (workdays > expectedWorkdays || workdays < 0 || (firstOpen && currentDate !== utils.moment().format('YYYY-MM-DD'))) {
            this.setState({ pulseDate: true }) 
        } else {
            this.setState({ pulseDate: false }) 
        }
    }
}

function _onHourChange(that, hours) {
    that.setState({ hours })
}

function _onCardMomentum(data, hours) {
    const { reportDates } = this.props
    const activeDate = reportDates[reportDates.length - 1]
    const nextDate = reportDates[reportDates.length - 2]
    const animateNextData = (activeDate.dataset.length > 1 && activeDate.dataset[activeDate.dataset.length-1].date) || null
    const animateNextDate = (!animateNextData && nextDate && nextDate.date) || null

    this.setState({ animateNextDate, animateNextData, lastCard: data })

    _onReportTime.call(this, data, hours)
}

async function _onReportTime(timereportdata, hours) {
    const { reportDates, fetchDataAsync } = this.props
    const currentDate = reportDates[reportDates.length - 1]
    let spread = currentDate.spread + hours - timereportdata.lastReportedHour

    const url = '/timereports/submit'
    const data = {
        "date"        : currentDate.date,
        "projectId"   : timereportdata.projectId,
        "userId"      : timereportdata.resourceId,
        "hours"       : hours || 0,
        "SM"          : timereportdata.salaryMultiplier,
        "timereportId": timereportdata.id || -1//Null om ny tidsrapport
    }

    await api(url, data, { useJWT: true })

    if (reportDates.length > 0) {
        const currentDate = reportDates[reportDates.length - 1]
        const nextDate = reportDates[reportDates.length - 2]
        const removedData = currentDate.dataset.pop()
        let newReportData

        if (nextDate) {
            nextDate.dataset.forEach(data => {   
                let nextReportData = getNextReportData(data, timereportdata.resourceId, timereportdata.projectId)
                
                if (nextReportData) nextReportData.lastReportedHour = hours
            })
        }

        if (currentDate.dataset.length > 0) newReportData = { ...currentDate }
        else newReportData = nextDate

        if (newReportData) {
            newReportData.spread = spread

            if (currentDate.isSingle) newReportData.isSingle = true
        }

        if (removedData) {
            this.props.timeReporter.removeUnreportedData(removedData.date, removedData.resourceId, removedData.projectId, removedData.type)
        }

        this.props.setTimeReportData(newReportData || null)
    } else this.close()

    await fetchDataAsync()
}

function getNextReportData(data, resourceId, projectId) {
    if (data.resourceId === resourceId && data.projectId === projectId) return data
    else return null
}

const Div = styled.div`
    ${props => props.className === 'avatar' && css`
        background: ${props.theme.label} 50% 50% no-repeat;
    `}
    ${props => props.className === 'date' && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('ReportTimeWindow-card') && css`
        background: ${props.theme.overlay} !important;
    `}
`