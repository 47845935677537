import React from 'react'
import styled, { css } from 'styled-components'

export default function IntegrationCards(props) {
    const { name, subtitle, onClick, connected, logo } = props

    return (
        <Div className="tagContainer" onClick={onClick}>
            <Div className="Timeline-toolbar punchClock-card">
                <div className="context-info" style={{ height: "100%" }}>
                    <Div $connected={connected} className="name">{name}</Div>
                    <div className='assigned'>{subtitle}</div>
                    {logo && <img className="integration-logo" alt="" src={logo} />}
                </div>
            </Div>
        </Div>
    )
}

const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
        background: ${props.theme.overlay} !important;
    `}

    ${props => props.className === 'name' &&
        (props.$connected ?
            css `color: ${props.theme.success}`
            : css `color: ${props.theme.danger}`
        )
    }
`;