import React from "react"
// Components
import PunchClockResources from "components/PunchClockResources/PunchClockResources.js"
// HELPERS
import api from "helpers/api"
import moment from "moment"
// OTHER
import Settings from "classes/Settings"

export default class PunchClock extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            resources: [],
            projects: [],
            todaysTimereports: [],
            yesterdaysTimereports: [],
            componentDidMount: false
        }
    }

    componentDidMount() {
        if (!Settings.get("PUNCHCLOCK_AUTH_TOKEN")) return
        this.resetInterval(moment().endOf("day").diff(moment(), "milliseconds"))
        this.fetchData()
        // this.startFetchDataInterval()
    }

    //repeated timer for when to reset inactive punchclocks
    resetInterval(time) {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout)
            this.resetInterval(moment().endOf("day").diff(moment(), "milliseconds"))
        }, time)
    }

    //resets inactive punchclocks
    _onGlobalReset() {
        const { state } = this
        const { resources, projects } = state

        resources.forEach((resource) => {
            projects.forEach((project) => {
                //if resource has project and project isnt active
                JSON.parse(project.projectResources).forEach((projectResource) => {
                    if (
                        projectResource.resourceInfo.resourceId === resource.ID &&
                        !resource.activeProjects.includes(project.Name)
                    ) {
                        resource.startArray[project.Name] = []
                        resource.stopArray[project.Name] = []
                    }
                })
            })
        })
    }

    startFetchDataInterval = async () => {
        var interval;
        let time = 5000
        if (this.interval) clearInterval(interval)
        interval = setInterval(async() => {
            if (this.state.componentDidMount) {
                this.fetchData()
            }
        }, time)
        return () => clearInterval(interval)
    }

    fetchData = async () => {
        const url = "punchclockboard/get"
        const result = await api(url)
        const resources = result.resources
        const projects = result.projects
        const todaysTimereports = result.todaysTimereports
        const yesterdaysTimereports = result.yesterdaysTimereports
        this.setState({ busy: false })
        this.setState({
            resources: result.resources,
            projects: result.projects,
            todaysTimereports: result.todaysTimereports,
            yesterdaysTimereports: result.yesterdaysTimereports
        },
            this.setData(
                resources,
                projects,
                todaysTimereports,
                yesterdaysTimereports
            )
        )
    }

    setData(resources, projects, todaysTimereports, yesterdaysTimereports) {
        resources.forEach((resource) => {
            //initiate start and stop array for resource
            if (!resource.startArray) {
                resource.startArray = []
                resource.stopArray = []
                resource.activeProjects = []
            }

            yesterdaysTimereports.forEach((ydayTimereport) => {
                if (resource.ID === ydayTimereport.UserID_FK) {
                    let projectId = ydayTimereport.ProjectID_FK
                    let startArray = JSON.parse(ydayTimereport.StartTimestamps)
                    let stopArray = JSON.parse(ydayTimereport.StopTimestamps)
                    //if timereport is active, use it
                    if (startArray.length !== stopArray.length) {
                        //assign timereport values to the correct project
                        Object.values(projects).forEach((project) => {
                            if (project.ID === projectId) {
                                resource.startArray[project.Name] = startArray
                                resource.stopArray[project.Name] = stopArray
                                resource.activeProjects.push(project.Name)
                            }
                        })
                    }
                }
            })

            todaysTimereports.forEach((timereport) => {
                if (resource.ID === timereport.UserID_FK) {
                    let projectId = timereport.ProjectID_FK
                    let startArray = JSON.parse(timereport.StartTimestamps)
                    let stopArray = JSON.parse(timereport.StopTimestamps)
                    //assign timereport values to the correct project
                    Object.values(projects).forEach((project) => {
                        if (project.ID === projectId) {
                            resource.startArray[project.Name] = startArray
                            resource.stopArray[project.Name] = stopArray
                            if (resource.startArray[project.Name].length > resource.stopArray[project.Name].length) {
                                resource.activeProjects.push(project.Name)
                            }
                        } 
                    })
                }
            })
        })
        this.setState({ componentDidMount: true })
    }

    render() {
        const { state } = this
        const { resources, projects, timereports, componentDidMount } = state
        return (
            <div className="PunchClockBoard">
                {componentDidMount && (
                    <div>
                        <PunchClockResources
                            searchUrl="/punchClockBoard/users/search/"
                            label={"Search"}
                            resources={resources}
                            projects={projects}
                            timereports={timereports}
                            reportTime={_reportTime.bind(this)}
                            timeElapsed={_timeElapsed.bind(this)}
                            onSingleReset={_onSingleReset.bind(this)}
                            onStartHandler={_onStartHandler.bind(this)}
                            onStopHandler={_onStopHandler.bind(this)}
                            source={this.state.source}
                        />
                    </div>
                )}
            </div>
        )
    }
}

async function _reportTime(userId, projectId, startArray, stopArray, HMS, salaryMultiplier) {
    const url = "/timereports/submit"
    let hours = HMS.hours + HMS.minutes / 60
    let rest = hours - Math.floor(hours)
    if (rest >= 0.25 && rest < 0.75) {
        hours = Math.floor(hours) + 0.5
    } else {
        hours = Math.floor(hours) + Math.round(rest)
    }

    const data = {
        timereportId: -1,
        date: moment(startArray[0], "YYYY-MM-DD:HH:mm:ss").format("YYYY-MM-DD"),
        projectId: projectId,
        userId: userId,
        hours: hours,
        SM: salaryMultiplier,
        startArray: startArray,
        stopArray: stopArray,
    }

    await api(url, data)
}

function _timeElapsed(startArray, stopArray) {
    let totalTime = 0

    const currentTime = moment().format("YYYY-MM-DD:HH:mm:ss")

    startArray.forEach((startTime, i) => {
        const stopTime = stopArray[i] ? stopArray[i] : currentTime

        totalTime += moment(stopTime, "YYYY-MM-DD:HH:mm:ss").diff(
            moment(startTime, "YYYY-MM-DD:HH:mm:ss"),
            "seconds"
        )
    })

    const hours = Math.floor(totalTime / 3600) || 0
    const minutes = Math.floor((totalTime - hours * 3600) / 60) || 0
    const seconds = totalTime - hours * 3600 - minutes * 60 || 0

    return { hours: hours, minutes: minutes, seconds: seconds }
}

function _onSingleReset(resource, project) {
    resource.startArray[project.Name] = []
    resource.stopArray[project.Name] = []
}

function _onStartHandler(resource, project) {
    let firstStart = resource.startArray[project.Name][resource.startArray[project.Name].length - 1]
    
    //failsafe if mobile device doesnt reset (happens if browser tab is docked)
    if (moment().diff(moment(firstStart, "YYYY-MM-DD:HH:mm:ss"), "days") !== 0) {
        _onSingleReset(resource, project)
    }

    resource.activeProjects.push(project.Name)

    const currentTime = moment().format("YYYY-MM-DD:HH:mm:ss")
    resource.startArray[project.Name].push(currentTime)
    let timeElapsed = _timeElapsed(resource.startArray[project.Name], resource.stopArray[project.Name])
    _reportTime(
        resource.ID,
        project.ID,
        resource.startArray[project.Name],
        resource.stopArray[project.Name],
        timeElapsed,
        project.SM
    )   

    return timeElapsed
}

function _onStopHandler(resource, project) {
    let firstStart = resource.startArray[project.Name][resource.startArray[project.Name].length - 1]
    const currentTime = moment().format("YYYY-MM-DD:HH:mm:ss")
    const firstStartDay = moment(firstStart, "YYYY-MM-DD:HH:mm:ss").format("DD")
    const currentDay = moment().format("DD")
    let daysDifferent = moment(currentDay, "DD").diff(moment(firstStartDay, "DD"),"days")
    //if started and stopped on different dates
    if (daysDifferent !== 0) {
        //for each day passed, report hours
        for (let index = 0; index < daysDifferent; index++) {
            resource.stopArray[project.Name].push(
              moment(firstStart, "YYYY-MM-DD:HH:mm:ss")
                .add(index, "days")
                .endOf("day")
                .format("YYYY-MM-DD:HH:mm:ss")
            )

            _reportTime(
                resource.ID,
                project.ID,
                resource.startArray[project.Name],
                resource.stopArray[project.Name],
                _timeElapsed(resource.startArray[project.Name], resource.stopArray[project.Name]),
                project.SM
            )
            _onSingleReset(resource, project)

            resource.startArray[project.Name].push(
                moment(firstStart, "YYYY-MM-DD:HH:mm:ss")
                    .add(index + 1, "days")
                    .startOf("day")
                    .format("YYYY-MM-DD:HH:mm:ss")
            )
        }
    }

    resource.activeProjects.forEach((e, index) => {
        if (e === project.Name) {
            resource.activeProjects.splice(index, 1)
        }
    })

    //push end of day into stopArray
    resource.stopArray[project.Name].push(currentTime)
    let timeElapsed = _timeElapsed(resource.startArray[project.Name], resource.stopArray[project.Name])
    _reportTime(
        resource.ID,
        project.ID,
        resource.startArray[project.Name],
        resource.stopArray[project.Name],
        timeElapsed,
        project.SM
    )

    return timeElapsed
}