import React from "react"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"
import Modal from "components/Modal/Modal"
import ProgressBar from "components/ProgressBar/ProgressBar"
import Settings from 'classes/Settings'
import styled, {css} from 'styled-components'
import EditableField from "components/EditableField/EditableField"
import DatePicker from "components/DatePicker/DatePicker"
import EditableTable from "../../components/EditableTable/EditableTable"
import * as utils from "helpers/utils"
import api, {apiStream} from "helpers/api"
import Switch from "components/Switch/Switch"
import ShowMessageBox from "components/MessageBox/MessageBox"
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import i18n from "helpers/i18n"
import { FormCheck } from "react-bootstrap"

export default class JiraContent extends React.PureComponent {
    constructor(props) {
        super(props)

        this.placeholderSuffix = i18n('jira', 'newPlaceholderSuffix')

        let timrUsers = props.data.timrUsers || []
        let jiraUsers = props.data.jiraUsers || []
        let connectedUsers = this.getConnectedUsers(timrUsers, jiraUsers)

        let timrProjects = props.data.timrProjects || []
        let jiraProjects = props.data.jiraProjects || []
        let connectedProjects = this.getConnectedProjects(timrProjects, jiraProjects)

        this.state = {
            connectedUsers,
            connectedProjects,
            timrUsers,
            jiraUsers,
            timrProjects,
            jiraProjects,
            config: {
                baseURL: "",
                email: "",
                apiToken: "",
                autoCreateUsers: true,
                autoCreateProjects: true,
                importWorklogs: true,
                importDate: utils.moment().startOf("year").format("YYYY-MM-DD"),
                ...props.data.config
            },
            connected: !!props.data.config.baseURL,
            connectStatus: "",
            connectInfoChanged: false,
            suggestPairing: true,
            submode: "config", // config, users, projects
            loading: false,
            showProgressBar: false,
            progressbar: {
                issue: {},
                worklog: {},
                timereport: {}
            }
        }
    }

    getConnectedUsers(timrUsers, jiraUsers) {
        let connectedUsers = []

        timrUsers.forEach((timrUser) => {
            if (!timrUser.accountId) return

            const jiraUser = jiraUsers.find((jiraUser) => {
                return jiraUser.id === timrUser.accountId
            })
    
            if (!jiraUser) return

            let connectedUser = {
                new: false,
                selected: false,
                
                timr: {
                    id: timrUser.id,
                    name: timrUser.name
                },
                jira: {
                    id: jiraUser.id,
                    name: jiraUser.name
                }
            }
    
            connectedUsers.push(connectedUser)
        })

        return connectedUsers
    }

    getConnectedProjects(timrProjects, jiraProjects) {
        let connectedProjects = []

        timrProjects.forEach((timrProject) => {
            if (!timrProject.jiraId) return

            const jiraProject = jiraProjects.find((jiraProject) => {
                return jiraProject.id === timrProject.jiraId
            })
    
            if (!jiraProject) return

            let connectedProject = {
                new: false,
                selected: false,
                
                timr: {
                    id: timrProject.id,
                    name: timrProject.name
                },
                jira: {
                    id: jiraProject.id,
                    name: jiraProject.name
                }
            }
    
            connectedProjects.push(connectedProject)
        })

        return connectedProjects
    }

    connectToJira = async () => {
        const config = this.state.config
        const { baseURL, email, apiToken } = config

        if (!baseURL || ((!email || !apiToken) && !this.props.data.config.baseURL)) return

        this.setState({ loading: true })

        const result = await api('jira/getEntities', {...config})
        if (result.success) {

            this.setState({
                jiraUsers: result.jiraUsers,
                jiraProjects: result.jiraProjects,
                connected: true,
                connectStatus: "success",
                connectInfoChanged: false,
                loading: false
            }, () => {
                this._addAllUsers()
                this._addAllProjects()
            })
        } else {
            this.setState({
                loading: false,
                connectInfoChanged: false,
                connectStatus: "failed",
                connected: false
            })
        }
    }

    changeSubmode = (submode) => this.setState({ submode })

    _addAllUsers = () => {
        const timrUsers = this.state.timrUsers || []
        const jiraUsers = this.state.jiraUsers || []
        const suggestPairing = this.state.suggestPairing
        
        let connectedUsers = [...this.state.connectedUsers]

        let availableJiraUsers = jiraUsers.filter((jiraUser) => {
            return !connectedUsers.find((user) => user.jira.id === jiraUser.id)
        })
        let unconnectedJiraUsers = availableJiraUsers

        timrUsers.forEach((timrUser) => {
            if (connectedUsers.find((user) => user.timr.id === timrUser.id)) return

            let jiraUser
            if (suggestPairing) {
                jiraUser = availableJiraUsers.find((jiraUser) => {
                    return jiraUser.email === timrUser.email || jiraUser.name.toLowerCase() === timrUser.name.toLowerCase()
                    || (timrUser.email && timrUser.email.split("@")[0] === jiraUser.name)
                })
            }

            let connectedUser = {
                new: !timrUser.accountId,
                selected: false,
                
                timr: {
                    id: timrUser.id,
                    name: timrUser.name
                },
                jira: {
                    id: "",
                    name: "",
                    placeholder: timrUser.name + this.placeholderSuffix
                }
            }

            if (jiraUser) {
                connectedUser.jira = {
                    id: jiraUser.id,
                    name: jiraUser.name
                }
                connectedUser.timr.placeholder = jiraUser.name + this.placeholderSuffix

                unconnectedJiraUsers = unconnectedJiraUsers.filter((user) => jiraUser.id !== user.id)
            }
            
    
            connectedUsers.push(connectedUser)
        })

        unconnectedJiraUsers.forEach((jiraUser) => {
            let connectedUser = {
                new: true,
                selected: false,
                
                timr: {
                    id: "",
                    name: "",
                    placeholder: jiraUser.name + this.placeholderSuffix
                },
                jira: {
                    id: jiraUser.id,
                    name: jiraUser.name
                }
            }

            connectedUsers.push(connectedUser)
        })

        this.setState({ connectedUsers })
    }

    _addAllProjects = () => {
        const timrProjects = this.state.timrProjects || []
        const jiraProjects = this.state.jiraProjects || []
        const suggestPairing = this.state.suggestPairing
        
        let connectedProjects = [...this.state.connectedProjects]

        let availableJiraProjects = jiraProjects.filter((jiraProject) => {
            return !connectedProjects.find((project) => project.jira.id === jiraProject.id)
        })
        let unconnectedJiraProjects = availableJiraProjects

        timrProjects.forEach((timrProject) => {
            if (connectedProjects.find((project) => project.timr.id === timrProject.id)) return

            let jiraProject
            if (suggestPairing) {
                jiraProject = availableJiraProjects.find((jiraProject) => {
                    return jiraProject.name === timrProject.name
                })
            }

            let connectedProject = {
                new: !timrProject.jiraId,
                selected: false,
                
                timr: {
                    id: timrProject.id,
                    name: timrProject.name
                },
                jira: {
                    id: "",
                    name: "",
                    placeholder: timrProject.name + this.placeholderSuffix
                }
            }

            if (jiraProject) {
                connectedProject.jira = {
                    id: jiraProject.id,
                    name: jiraProject.name
                }
                connectedProject.timr.placeholder = jiraProject.name + this.placeholderSuffix

                unconnectedJiraProjects = unconnectedJiraProjects.filter((project) => jiraProject.id !== project.id)
            }
            
    
            connectedProjects.push(connectedProject)
        })

        unconnectedJiraProjects.forEach((jiraProject) => {
            let connectedProject = {
                new: true,
                selected: false,
                
                timr: {
                    id: "",
                    name: "",
                    placeholder: jiraProject.name + this.placeholderSuffix
                },
                jira: {
                    id: jiraProject.id,
                    name: jiraProject.name
                }
            }

            connectedProjects.push(connectedProject)
        })

        this.setState({ connectedProjects })
    }

    checkAll = (event) => {
        const submodeMapping = {
            "users": "connectedUsers",
            "projects": "connectedProjects"
        }
        const type = submodeMapping[this.state.submode]

        if (type) {
            const rows = [...this.state[type]]
            
            const checkedRows = rows.map((row) => {
                return {
                    ...row,
                    selected: event.target.checked
                }
            })
    
            this.setState({
                [type]: checkedRows
            })
        }
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const rows = [...this.state[type]]
        rows[rowIndex][colKey] = checked

        this.setState({
            [type]: rows
        })
    }

    _onRemoveSelected = async (type) => {
        const rows = this.state[type].filter(row => {
            return !row.selected
        })

        this.setState({ [type]: rows })
    }

    _onAddRow = (type) => {
        const rows = [...this.state[type]]

        rows.unshift({
            new: true,
            selected: false,
            timr: {
                id: "",
                name: ""
            },
            jira: {
                id: "",
                name: ""
            }
        })

        this.setState({
            [type]: rows
        })
    }

    onProjectFieldChange = (type, value, rowIndex) => {
        this._onEditableFieldChange("connectedProjects", type, value, rowIndex)
    }

    onUserFieldChange = (type, value, rowIndex) => {
        this._onEditableFieldChange("connectedUsers", type, value, rowIndex)
    }

    _onEditableFieldChange = (rowType, type, value, rowIndex) => {
        if (!value) {
            const rows = [...this.state[rowType]]
            let row = rows[rowIndex]
            let otherType = type === "timr" ? "jira" : "timr"

            row = {
                ...row,
                new: true,
                [type]: {
                    id: "",
                    name: "",
                    placeholder: row[type]?.placeholder
                }
            }

            delete row[otherType].placeholder

            if (!row[otherType].id) {
                delete row[type].placeholder
            }
    
            rows[rowIndex] = row
    
            this.setState({
                [rowType]: rows
            })
        }
    }

    onChange = (value, name) => {
        this.setState({ [name]: value })
    }

    onImportDateChange = (event) => {
        const { value } = event.target
        const name = 'importDate'

        this.onConfigChange(value, name)
    }

    onConfigChange = (value, name) => {
        const isConnectInfo = ["baseURL", "email", "apiToken"].includes(name)

        this.setState(prevState => ({
            connectInfoChanged: isConnectInfo ? true : prevState.connectInfoChanged,
            config: {
                ...prevState.config,
                [name]: value
            }    
        }))
    }

    onConnectInfoBlur = () => {
        if (this.state.connectInfoChanged) this.connectToJira()
    }

    saveJiraData = async () => {
        this.setState({
            isSaving: true,
            showProgressBar: this.state.config.importWorklogs
        })

        // adding a user in jira requires an email address
        let missingRequiredEmails = []
        this.state.connectedUsers.forEach((user) => {
            if (user.timr.id && !user.jira.id) {
                const timruser = this.state.timrUsers.find((timruser) => timruser.id === user.timr.id)

                if (!timruser.email) {
                    missingRequiredEmails.push(timruser)
                }
            }
        })

        if (missingRequiredEmails.length > 0) {
            ShowMessageBox(this, {
                message: "Missing email",
                customContent: <div>
                    {missingRequiredEmails.map((user) => {
                        return <EditableField key={user.id} label={user.name} name="email" type="text" />
                    })}
                </div>,
                buttons: [{
                    label: i18n('warnings', 'confirm'),
                    role: 'confirm',
                    appearance: 'primary',
                    onClick: () => {

                    }
                }, {
                    label: i18n('warnings', 'cancel'),
                    role: 'cancel',
                    appearance: 'danger'
                }],
            })
        }

        const success = await apiStream("/jira/save", {...this.state}, (data) => {
            const progressbar = data.progress

            if (!progressbar) return

            this.setState({ progressbar })
        })

        if (success) return this.props.onSaved()

        ShowMessageBox(this, {
            message: i18n('jira', 'import_failed'),
            buttons : [{
                label: 'OK',
                appearance: 'primary',
                onClick: () => this.props.onSaved()
            }]
        })
    }

    deleteJiraInstance = () => {
        ShowMessageBox(this, {
            message: i18n('jira', 'confirm_delete'),
            buttons : [
                {
                    label: i18n('warnings', 'confirm'),
                    appearance: 'primary',
                    onClick: async () => {
                        await api("/jira/delete")
                        this.props.onSaved()
                    }
                },
                {
                    label: i18n('warnings', 'cancel'),
                    appearance: 'danger'
                }
            ]
        })
    }

    getAssetIds(assets) {
        const timr = []
        const jira = []
        assets.forEach((asset) => {
            if (asset.timr.id) timr.push(asset.timr.id)
            if (asset.jira.id) jira.push(asset.jira.id)
        })

        return {
            timr, jira 
        }
    }

    cancelImport = () => {
        ShowMessageBox(this, {
            message: i18n('jira', 'confirm_stop_import'),
            buttons : [
                {
                    label: i18n('jira', 'stop_import'),
                    appearance: 'primary',
                    onClick: () => {
                        window.location.reload()
                    }
                },
                {
                    label: i18n('jira', 'continue_import'),
                    appearance: 'danger'
                }
            ]
        })
    }

    onOutsideClick = () => {
        this.cancelImport()

        return false
    }

    render() {
        const { connected, submode, connectedUsers, connectedProjects, suggestPairing, config,
            timrUsers, jiraUsers, timrProjects, jiraProjects, connectStatus, loading, progressbar, showProgressBar } = this.state
        const { baseURL, email, apiToken, autoCreateUsers, autoCreateProjects, importWorklogs, importDate } = config

        const issueProgress = progressbar["issue"]
        const worklogProgress = progressbar["worklog"]
        const timereportProgress = progressbar["timereport"]

        return (
            <div>
                {!showProgressBar && this.state.MessageBox}
                <Modal visible={showProgressBar} onOverlayClick={this.onOutsideClick}>
                    {this.state.MessageBox}
                    <p style={{margin: "15px"}}>{i18n('jira', 'save_success')}</p>
                    <h3>{i18n('jira', 'importing_worklogs')}</h3>
                    <ProgressBar label={i18n('jira', 'fetching_issues')} progress={issueProgress.progress} total={issueProgress.total} />
                    <ProgressBar label={i18n('jira', 'processing_issues')} progress={worklogProgress.progress} total={worklogProgress.total} />
                    <ProgressBar label={i18n('jira', 'converting_worklogs')} progress={timereportProgress.progress} total={timereportProgress.total} />
                    <Button
                        type="submit"
                        label={i18n('jira', 'cancel_imports')}
                        appearance="danger"
                        filled
                        onClick={ this.cancelImport }
                    />
                </Modal>

                <h2 style={{marginTop: 0}}>Jira</h2>

                <SubmodeNav connected={connected} activeSubmode={submode} onChange={this.changeSubmode} />

                <div style={{marginTop: "20px"}}>
                    {{
                        "config": <div>
                            <EditableField required type="monitor" label={i18n('general', 'url')} name="baseURL" value={ baseURL } onChange={this.onConfigChange} onBlur={this.onConnectInfoBlur} />
                            <EditableField required type="monitor" label={i18n('login', 'email')} name="email" value={email} onChange={this.onConfigChange} onBlur={this.onConnectInfoBlur} />
                            <EditableField required={!this.props.data.config.baseURL} type="monitor" label={i18n('jira', 'api_token')} placeholder={this.props.data.config.baseURL ? i18n('general', 'leave_blank_to_keep_current') : ""} name="apiToken" value={apiToken} onChange={this.onConfigChange} onBlur={this.onConnectInfoBlur} />

                            <StackLayout style={{margin: "0 0 0 5px", height: "35px"}}>
                                {loading
                                    ? <span style={{scale: "0.6", margin: "-8px 0"}}>
                                        <ActivityIndicator busy />
                                    </span>
                                    : <div style={{margin: "5px", fontSize: "14px"}}>
                                        {i18n('jira', 'generate_api_token')} <a href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank" rel="noreferrer">{i18n('general', 'here')}</a>

                                        <ConnectStatus $status={connectStatus} style={{marginTop: "5px"}}>
                                            {connectStatus === "success" && i18n('jira', 'connection_success')}
                                            {connectStatus === "failed" && i18n('jira', 'connection_failed')}
                                        </ConnectStatus>
                                    </div>
                                }
                            </StackLayout>

                            <Switch label={i18n('jira', 'add_jira_users_to_timr')} name="autoCreateUsers" checked={autoCreateUsers} onChange={this.onConfigChange} animate />
                            <Switch label={i18n('jira', 'add_jira_projects_to_timr')} name="autoCreateProjects" checked={autoCreateProjects} onChange={this.onConfigChange} animate />
                        </div>,
                        "users": <div>
                            <Switch label={i18n('jira', 'suggest_pairing')} name="suggestPairing" checked={suggestPairing} onChange={this.onChange} animate />
                            <EditableTable
                                context={ this }
                                type={ "connectedUsers" }
                                rows={ connectedUsers }
                                chosenAssets={ this.getAssetIds(connectedUsers) }
                                columns={[
                                    { key: "selected", label: <FormCheck key={submode} onChange={this.checkAll} />, width: "5%" },
                                    { name: "timr",    label: `Timr ${i18n('globals', 'users')}`,     width: "50%" },
                                    { name: "jira",    label: `Jira ${i18n('globals', 'users')}`,     width: "50%" },
                                ]}
                                timrUsers={timrUsers}
                                jiraUsers={jiraUsers}
                                onRowChecked={ this._onRowChecked }
                                onRemoveSelected={ this._onRemoveSelected }
                                onAddRow={ this._onAddRow }
                                addAllUsers={ this._addAllUsers }
                                onChange={this.onUserFieldChange}
                            />
                        </div>,
                        "projects": <div>
                            <Switch label={i18n('jira', 'suggest_pairing')} name="suggestPairing" checked={suggestPairing} onChange={this.onChange} animate />
                            <EditableTable
                                context={ this }
                                type={ "connectedProjects" }
                                rows={ connectedProjects }
                                chosenAssets={ this.getAssetIds(connectedProjects) }
                                columns={[
                                    { key: "selected", label: <FormCheck key={submode} onChange={this.checkAll} />, width: "5%" },
                                    { name: "timr",    label: `Timr ${i18n('globals', 'projects')}`,  width: "50%" },
                                    { name: "jira",    label: `Jira ${i18n('globals', 'projects')}`,  width: "50%" },
                                ]}
                                timrProjects={timrProjects}
                                jiraProjects={jiraProjects}
                                onRowChecked={ this._onRowChecked }
                                onAddRow={ this._onAddRow }
                                onRemoveSelected={ this._onRemoveSelected }
                                addAllProjects={ this._addAllProjects }
                                onChange={this.onProjectFieldChange}
                            />
                        </div>
                    }[submode]}
                </div>

                {this.props.data.config.baseURL && <Button
                    style={{float: "right"}}
                    type="submit"
                    label={i18n('general', 'delete')}
                    appearance="danger"
                    filled
                    onClick={ this.deleteJiraInstance }
                />}
                <StackLayout style={{marginBottom: 0}} orientation="horizontal" alignItems="center" justifyContent="end" group>
                    <div className="display-flex">
                        <Switch label={i18n('jira', 'should_import')} name="importWorklogs" checked={importWorklogs} onChange={this.onConfigChange} animate />
                        {importWorklogs && <span style={{marginTop: "-2px"}}>
                            <DatePicker label={i18n('jira', 'import_date')} date={importDate ? utils.moment(importDate) : null} onDateChange={this.onImportDateChange} />
                        </span>}
                    </div>

                    <Button
                        type="submit"
                        label={i18n('general', 'save')}
                        appearance="primary"
                        filled
                        busy={this.state.isSaving}
                        disabled={!this.state.connected}
                        onClick={this.saveJiraData}
                    />
                </StackLayout>                
            </div>
        )
    }
}

class SubmodeNav extends React.PureComponent {
    render() {
        const { activeSubmode, onChange, connected } = this.props

        const Item = ({ label, submode, disabled}) => {
            return (
                <Div className={ utils.createClassName("item", { "active": activeSubmode === submode, "disabled": disabled }) } onClick={ (onChange) && (() => !disabled && onChange(submode)) } >{ label }</Div>
            )
        }

        return (
            <Div className={ "ContextReviewContent-EditToggler active" } >
                <Div className="togglerShadow">
                    <nav>
                        <Item label={ "config" } submode="config" />
                        <Item label={ "users" } submode="users" disabled={!connected} />
                        <Item label={ "projects" } submode="projects" disabled={!connected} />
                    </nav>
                </Div>
            </Div>
        )
    }
}

const ConnectStatus = styled.p`
    ${props => css`
        color: ${Settings.getGlobalColor(props.$status === "success" ? "success" : "danger")};
    `}
`
const Div = styled.div`
    ${props => props.className.split(' ').includes('item') && css`
        color: ${Settings.getGlobalColor('label')};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${Settings.getGlobalColor('primary')};
    `}

    ${props => props.className.split(' ').includes('togglerShadow') && css`
        background: ${Settings.getGlobalColor('background')};
        :hover {
            background: ${Settings.getGlobalColor('background')};
        }
        &::-webkit-scrollbar-thumb {
            background: ${Settings.getGlobalColor('primary')};
        }
        &::-webkit-scrollbar-track {
            background: ${Settings.getGlobalColor('label')}80; 
            opacity: 0.5
        }
    `}
`;