import React from "react";
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

export default function ProgressBar(props) {
    const { label, progress, total } = props
    const percent = progress && total ? Math.round((progress / total) * 100) : 0

    return (
        <div style={{padding: "10px 0"}}>
            <div className="display-flex justify-between">
                <span>{label}</span>
                {(progress != null && total != null) && <span>{progress} / {total} ({percent}%)</span>}
            </div>
            <Bar $percent={percent} $backgroundColor={Settings.getGlobalColor("background")} $progressColor={Settings.getGlobalColor("primary")} />
        </div>
    )
}

const Bar = styled.div`
    height: 30px;
    width: 100%;
    padding: 5px 20px;
    border-radius: 4px;
    border: 1px solid black;
    ${props => css`
        background: linear-gradient(90deg, ${props.$progressColor} ${props.$percent}%, ${props.$backgroundColor} ${props.$percent}%);
    `}
`