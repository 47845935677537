import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from "components/Button/Button"
import DatePicker from "components/DatePicker/DatePicker"
import styled from 'styled-components'
import EditableTable from "routes/TimeReport/components/EditableTable/EditableTable"

// HELPERS
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

export class MessageBox extends Component {
    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.string,
        buttons: PropTypes.array.isRequired,
        childrenElement: PropTypes.func,
        customUI: PropTypes.func,
        willUnmount: PropTypes.func
    }

    static defaultProps = {
        buttons: [
            {
                label: 'Ok',
                appearance: 'primary',
                onClick: () => null
            }
        ],
        childrenElement: () => null,
        willUnmount: () => null
    }

    state = {
        date: null,
        warning: null,
        data: {
            affectedAssignings: this.props.affectedAssignings
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown)
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown)
    }

    _handleKeyDown = (event) => {
        if (event.key === "Enter") {this.handleClickButton(this.props.buttons[0])}
    }

    componentDidUpdate() {
        const { date, warning } = this.state

        if (date && warning) this.setState({ warning: null })
    }

    handleClickButton = button => {
        if (button.onClick) {
            if (this.props.datepicker) {
                if (this.state.date || button.role !== 'confirm') button.onClick(this.state.date)
                else return this.setState({ warning: 'select_date' }, () => {
                    setTimeout(() => this.setState({ warning: null }), 10000)
                })
            } else if (this.state.data.affectedAssignings) {
                button.onClick(this.state.data.affectedAssignings).then((result) => {
                    if (!result.success) {
                        this.setState({
                            markedPeriods: result.ids,
                            assignWarning: result.message
                        })
                    } else {
                        if (this.props.onClose) this.props.onClose();
                    }
                })
            } else { button.onClick() }
        }

        if (this.props.onClose && !this.state.data.affectedAssignings) this.props.onClose();
    }

    _onDateChange = ({ target }) => {
        let chosenDate = utils.moment(target.value)
        let today = utils.moment().format('YYYY-MM-DD')

        if (chosenDate.isBefore(today)) this.setState({ date: null, warning: 'date_not_before' }, () => {
            setTimeout(() => this.setState({ warning: null }), 10000)
        })

        else this.setState({ date: target.value })
    }

    _onTrashClick(rowIndex) {
        //keep component height
        let componentHeight = document.getElementById("alert-body").clientHeight

        let affectedAssignings = [...this.state.data.affectedAssignings]
        affectedAssignings[rowIndex].delete = true

        this.setState(prevState => ({data: {...prevState.data, affectedAssignings}, componentHeight}))
    }

    render () {
        const { title, message, buttons, links, prevUserStatus, datepicker, customContent } = this.props
        const { date, warning, data, componentHeight, assignWarning, markedPeriods } = this.state
        const statusTitle = prevUserStatus ? i18n('warnings', 'activate') : i18n('warnings', 'deactivate')
        const prevStatusTitle = prevUserStatus ? i18n('warnings', 'inactive') : i18n('warnings', 'active')

        return (
            <div className='alert-overlay'>
                <div className='alert'>
                    <AlertBody className='alert-body' style={{height: componentHeight ? componentHeight : "inherit"}} id="alert-body">
                        {datepicker ? <div className='alert-message'>
                            {<Title>{statusTitle}:</Title>}{message}
                        </div>
                        : <div className='alert-message'>
                            {title && <Title>{title}:</Title>}{message}
                        </div>}
                        
                        {data.affectedAssignings && <EditableTable
                            context={ this }
                            type={ "affectedAssignings" }
                            rows={ data.affectedAssignings }
                            markedPeriods={ markedPeriods }
                            columns={[
                                { key: "delete",     label: "",                                        width: "5%" },
                                { name: "Project",   label: `${i18n('editable_table', 'project')}`,    width: "20%" },
                                { name: "StartDate", label: `${i18n('editable_table', 'start_date')}`, width: "30%" },
                                { name: "EndDate",   label: `${i18n('editable_table', 'end_date')}`,   width: "30%" },
                            ]}
                            footerTemplate={<InputWarning>{assignWarning}</InputWarning>}
                            onTrashClick={this._onTrashClick.bind(this)}
                        />}

                        {datepicker && <DatePicker messageBox
                            date={date ? utils.moment(date) : null}
                            onDateChange={this._onDateChange}
                        />}

                        {date && <div className='alert-message date'>
                            <div className='alert-message-row'>
                                {utils.moment().format('YYYY-MM-DD') !== date && <span>{prevStatusTitle} {i18n('general', 'until')}: </span>}
                                <span>{statusTitle} {i18n('general', 'on_date')}: </span>
                            </div>
                            <div className='alert-message-row'>
                                {utils.moment().format('YYYY-MM-DD') !== date && <b>{utils.moment(date).subtract(1, 'days').format('YYYY-MM-DD')}</b>}
                                <b>{date}</b>
                            </div>
                            
                        </div>}

                            {customContent}
                        <div className='alert-button-group'>
                            {buttons.map((button, i) => (
                                <Button type="button"
                                    size = "small"
                                    key={i}
                                    label={ button.label }
                                    appearance={button.appearance} filled
                                    onClick={() => this.handleClickButton(button)}
                                    />
                            ))}
                        </div>

                        {links && links.map((link, i) => (
                            <Link key={i} text={link.text} onClick={() => this.handleClickButton(link)} />
                        ))}

                        {warning && <div className='alert-message error'>
                            {i18n('warnings', warning)}
                        </div>}
                    </AlertBody>
                </div>
            </div>
        )
    }
}

const AlertBody = styled.div`
    color: ${props => props.theme.label};
    background: ${props => props.theme.background};
`;

const InputWarning = styled.p`
    color: ${props => props.theme.danger};
    font-size: 14px;
`;

const Link = (props) => {
    const { text, onClick } = props

    return <LinkButton className='button-link' onClick={onClick}>{text}</LinkButton>
}

const LinkButton = styled.button`
    color: ${props => props.theme.default}
`

const Title = styled.b`
    color: ${props => props.theme.subtitle}
`

export default function ShowMessageBox(context, args) {
    context.setState({
        MessageBox: <MessageBox
            title={args.title}
            message={args.message}
            customContent={args.customContent}
            buttons={args.buttons}
            links={args.links}
            prevUserStatus={args.prevUserStatus}
            datepicker={args.datepicker}
            affectedAssignings={args.affectedAssignings}
            onClose={() => context.setState({ MessageBox: null })}
            />
    });
}
