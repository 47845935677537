import React from "react";

// HELPERS
import { createClassName } from "helpers/utils";


export default function StackLayout(props) {
    const { style, orientation, justifyContent, alignItems, rowWrap, fullWidth, group } = props;
    const classes = createClassName(props.className, {
        "StackLayout": true,
        "horizontal": orientation !== "vertical",
        "vertical": orientation === "vertical",
        "jc-s": justifyContent === "start",
        "jc-c": justifyContent === "center",
        "jc-e": justifyContent === "end",
        "jc-sb": justifyContent === "space-between",
        "jc-sa": justifyContent === "space-around",
        "ai-s": alignItems === "start",
        "ai-c": alignItems === "center",
        "ai-e": alignItems === "end",
        "ai-st": alignItems === "stretch",
        "row-wrap": rowWrap === true,
        "full-width": fullWidth === true,
        "group": group === true,
    });

    return (
        <div className={ classes } style={ style }>{ props.children }</div>
    );
}