import React, { Component } from 'react'
import PropTypes from "prop-types"
import { createClassName } from "helpers/utils"

import Settings from "classes/Settings"
import styled, { css } from 'styled-components'

import api, {
    getAssetsURIForPath
} from "helpers/api"
import i18n from "helpers/i18n"
import Autosuggest from "react-autosuggest"

export default class Autocomplete extends Component {
    static propTypes = {
        value: PropTypes.string,
        item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        type: PropTypes.string,
        searchUrl: PropTypes.string,
        name: PropTypes.string
    }

    constructor(props) {
        super(props)
        this.state = {
            value: props.value || "",
            items: [],
            filled: !!props.value,
            focussed: false,
            assetType: props.assetType
        }
    }

    componentDidMount() {
        this.getInfo(this.props.value)
    }

    getInfo = async (value, currentAssets = null) => {
        const { canCreateTag, searchUrl, chosenAssets, startDate, endDate, items } = this.props
        const url = searchUrl
        const not = currentAssets ? [...currentAssets] : (chosenAssets ? [...chosenAssets] : [])

        const foundNames = []
        if (!items) {
            const formData = {
                "prefix": value,
                "limit": 7,
                "not": not,
                "startDate": startDate, // dates are used for filtering inactive users in monitor where the reference date is not necessarily todays date
                "endDate": endDate
            }
    
            const result = await api(url, formData, { useJWT: true })
    
            for (let i = 0; i < result.data.length; i++) {
                foundNames.push({
                    id: result.data[i].ID,
                    label: result.data[i].label,
                    data: result.data[i].data,
                    avatarPath: result.data[i].avatarPath,
                    avatar: result.data[i].avatar,
                    type: result.data[i].type,
                    shadow: result.data[i].shadow,
                })
            }
        } else {
            for (let i = 0; i < items.length && foundNames.length < 7; i++) {
                const label = items[i].label || items[i].name

                if (label.toLowerCase().includes(value.toLowerCase()) && !chosenAssets.includes(items[i].id)) {
                    foundNames.push({
                        id: items[i].id,
                        label: label,
                        data: items[i].data,
                        avatarPath: items[i].avatarPath,
                        avatar: items[i].avatar,
                        type: items[i].type,
                        shadow: items[i].shadow
                    })
                }   
            }
        }

        // if able to create tags and value isnt empty and value isnt already in foundNames[]
        if (canCreateTag && value.length > 0
            && !foundNames.find(e => e.label === value) && !not.find(e => e.Name === value)) {
            foundNames.unshift({
                isNew: true,
                id: -1,
                label: value
            })
        }

        this.setState({ items: foundNames })
    }

    onValueChange = (event, item) => {
        if (item.method === 'click') return

        const { value } = event.target
        this.setState({ value, filled: !!value })

        const { onChange, isValueSet, rowIndex, searchUrl, name } = this.props
        const url = searchUrl !== '/users/general/search/' ? 'companyname' : 'assigning'

        if (isValueSet) isValueSet(!!value)
        if (onChange) onChange(name || url, value, rowIndex)
    }

    onSelected = (item) => {
        const { onSelect, isMonitor, isTagField } = this.props
        const suggestion = item.suggestion

        if (isMonitor || isTagField) {
            this.setState({
                value: '',
                filled: false
            }, () => {
                this.getInfo(this.state.value)
            })
        } else {
            this.setState({ value: suggestion.label, filled: true })
        }

        if (onSelect) onSelect(suggestion)
    }

    _onFocus = () => {
        this.setState({ focussed: true })
        this.props.onFocus?.()
    }

    _onBlur = async () => {
        this.setState({ focussed: false })
        this.props.onBlur?.()
    }

    render() {
        const { items, filled, value, focussed } = this.state
        const { small, label, style, required, isTagField, placeholder } = this.props
        const autocompleteClasses = createClassName('TextField text autocomplete', {
            "filled": filled || !!value,
        })

        const inputProps = {
            placeholder: placeholder,
            value,
            onChange: this.onValueChange
        };

        if (!small) {
            let menuStyle = {
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                zIndex: 999,
                width: "100%"
            }

            return (
                <div style={{ position: 'relative', width: '100%', ...(style || {}) }}>
                    <Autosuggest
                        suggestions={items}
                        focusInputOnSuggestionClick={false}
                        shouldRenderSuggestions={() => true}
                        onSuggestionsFetchRequested={(e) => this.getInfo(e.value)}
                        onSuggestionsClearRequested={this._onBlur}
                        getSuggestionValue={suggestion => suggestion.label}
                        onSuggestionSelected={(_, item) => this.onSelected(item)}
                        inputProps={inputProps}
                        renderSuggestion={(item, { isHighlighted }) => {
                            const background = Settings.getGlobalColor(isHighlighted ? "background" : "overlay")
                            return (
                                <div className='autocomplete-row' key={item.id} style={{ background }}>
                                    {item.avatarPath && <img src={getAssetsURIForPath(item.avatarPath)} className="avatar" alt='' />}
                                    {item.label}
                                </div>
                            )
                        }}
                        renderSuggestionsContainer={({ children, containerProps }) => <MenuStyle {...containerProps} key={containerProps.key} style={ menuStyle } children={children} />}
                        renderInputComponent={(params) => {
                            return (
                                <Div className={autocompleteClasses + (focussed ? ' focus' : '')}>
                                    <label className={'TextField-label'} style={{ color: !label ? Settings.getGlobalColor('label') : 'auto' }}>{label !== undefined ? label : i18n('general', 'company_name')}</label>

                                    <input
                                        {...params}
                                        key={params.key}
                                        className={'TextField-input'}
                                        onFocus={() => { params.onFocus(); this._onFocus() }}
                                    />

                                    {required && (
                                        <div className="TextField-status">
                                            <div className="required-symbol">*</div>
                                        </div>
                                    )}
                                </Div>
                            )
                        }}
                    />
                </div>
            )
        } else {
            let menuStyle = {
                display: "block",
                background: Settings.getGlobalColor('overlay')
            }

            return (
                <div className={"autocomplete-container small"}>
                    <Autosuggest
                        suggestions={items}
                        focusInputOnSuggestionClick={false}
                        shouldRenderSuggestions={() => true}
                        onSuggestionsFetchRequested={(e) => this.getInfo(e.value)}
                        onSuggestionsClearRequested={this._onBlur}
                        getSuggestionValue={suggestion => suggestion.label}
                        onSuggestionSelected={(_, item) => this.onSelected(item)}
                        inputProps={inputProps}
                        renderSuggestion={(item, { isHighlighted }) => {
                            const background = Settings.getGlobalColor(isHighlighted ? "background" : "overlay")
                            return (
                                <div key={item.id} className='autocomplete-row' style={{ background }}>
                                    {!isTagField && <img src={item.avatarPath ? getAssetsURIForPath(item.avatarPath) : undefined} className="avatar" alt='' />}
                                    <p className='name'>{item.label}</p>
                                </div>
                            )
                        }}
                        renderSuggestionsContainer={({ children, containerProps }) => <MenuStyle {...containerProps} key={containerProps.key} style={{ ...menuStyle }} children={children} />}
                        renderInputComponent={(params) => {
                            return (
                                <div className={(focussed ? ' focus' : '')}>
                                    {(placeholder && !value) && (
                                        <label className={'TextField-label'} style={{ color: Settings.getGlobalColor('label') }}>
                                            {placeholder}
                                        </label>
                                    )}

                                    <input
                                        {...params}
                                        placeholder={null}
                                        key={params.key}
                                        onFocus={() => { params.onFocus(); this._onFocus() }}
                                    />
                                </div>
                            )
                        }}
                    />
                </div>
            )
        }
    }
}

const Div = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.background};
    ${props => props.className.split(' ').includes('focus') && css`
        border-bottom: 1px solid ${props.theme.secondary};
    `}
`

const MenuStyle = styled.div`
    ul {
        position: relative;
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        background: ${props => props.theme.overlay};
        list-style-type: none;
        border-radius: 3px;
        width: 100%;
        minHeight: 42px;
    }
`