import axios from "axios"

// CLASSES
import Settings from "classes/Settings"

export default function apiRequest(path, data, props = {}) {
    if (!data) data = {}

    const apiUrl = process.env.REACT_APP_API_URL
    const url = path[0] === "/" ? apiUrl + path : apiUrl + "/" + path
    const headers = {}

    if (window.location.pathname.includes("punchclock")) {
        const punchClockAuthToken = Settings.get("PUNCHCLOCK_AUTH_TOKEN")

        if (punchClockAuthToken !== null) headers["Authorization"] = `Bearer ${ punchClockAuthToken }`
    } else {
        const authToken = Settings.get("AUTH_TOKEN")

        if (authToken !== null) headers["Authorization"] = `Bearer ${ authToken }`
    }

    const config = {
        method: "post",
        data,
        url,
        ...props,
        headers: { ...headers, ...props.headers }
    }

    return new Promise((resolve, reject) => {
        axios(config).then(response => {
            if (response.data) {
                console.log(response.data)
                resolve(response.data)
            } else {
                reject(response.data.error)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    //User is not authorized. Kick him out.
                    // localStorage.clear()

                    if (!window.location.pathname.includes("punchclock")
                        && !window.location.pathname.includes("login")
                        && window.location.pathname !== '/')
                    {
                        window.location.href = "/"
                    }
                }

            } else if (error.request) {
                console.log('request', error.request)
                console.log('error data', error.data)
            } else {
                console.log('Error message', error.message)
            }

            console.log('config', error.config)
            reject(error.data)
        })
    })
}

export const apiStream = async function(path, data, callback, props = {}) {
    const apiUrl = process.env.REACT_APP_API_URL
    const url = path[0] === "/" ? apiUrl + path : apiUrl + "/" + path

    const headers = {'Content-Type': 'application/json'}

    if (window.location.pathname.includes("punchclock")) {
        const punchClockAuthToken = Settings.get("PUNCHCLOCK_AUTH_TOKEN")

        if (punchClockAuthToken !== null) headers["Authorization"] = `Bearer ${ punchClockAuthToken }`
    } else {
        const authToken = Settings.get("AUTH_TOKEN")

        if (authToken !== null) headers["Authorization"] = `Bearer ${ authToken }`
    }

    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        ...props,
        headers: {...headers, ...props.headers}
    }

    try {
        await new Promise((resolve, reject) => {
            new ReadableStream({
                start() {
                    fetch(
                        url,
                        config
                    ).then(response => {
                        if (!response.ok) {
                            reject('Network response was not ok')
                        }
                        return response.body
                    }).then(body => {
                        const reader = body.getReader();
    
                        function read() {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    return resolve()
                                }
    
                                const response = new TextDecoder().decode(value);
                                response.split("__DELIMITER__").forEach((receivedData) => {
                                    if (!receivedData) return
                                    // Try parsing the JSON
                                    try {
                                        const data = JSON.parse(receivedData);
                                        callback(data)
                                        console.log("Stream data", data)
                                    } catch (error) {
                                        console.log("Stream Error", error, receivedData)
                                        reject(error)
                                    }
                                })
                                read() // Continue reading
                            })
                        }
                        read() // Start reading
                    }).catch(error => {
                        console.error('Error:', error)
                        reject()
                    })
                }
            })
        })

        return true
    } catch(error) {
        console.log(error)
        return false
    }
}

export const getAssetsURIForPath = function(path) {
    return encodeURI(`${process.env.REACT_APP_API_URL}/assets/img${ path }`)
}

