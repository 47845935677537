import React from 'react'
import styled, {css} from 'styled-components'
import Swiper from 'swiper'
import {FreeMode} from 'swiper/modules'

// HELPERS
import * as utils from "helpers/utils"

export default class NumberSlider extends React.Component {
    constructor(props) {
        super(props)

        const lastReportedHour = props.lastReportedHour || 0

        this.numbers = []
        for (let i = 0; i <= 24; i += 0.5) { this.numbers.push(i) }
        if (props.lastReportedHour > 24) this.numbers.push(props.lastReportedHour)

        const lastReportedIndex = this.numbers.findIndex(number => Number(number) === Number(lastReportedHour))

        this.state = {
            selectedIndex: lastReportedIndex !== -1 ? lastReportedIndex : 0,
            inTransition: false
        }
    }

    componentDidMount() {
        this.mySwiper = new Swiper('#swiper-container', {
            direction: 'vertical',
            initialSlide: this.state.selectedIndex,
            spaceBetween: 0,
            slidesPerView: 3,
            slidesPerGroup: 1,
            centeredSlides: true,
            modules: [FreeMode],
            freeMode: {
                enabled: true,
                momentumBounce: true,
                momentumRatio: utils.isMobileDevice() ? 0.4 : 1,
                momentumVelocityRatio: utils.isMobileDevice() ? 0.9 : 1,
                minimumVelocity: utils.isMobileDevice() ? 0.05 : 0.2,
                sticky: true
            },
            grabCursor: true,
            preventClicks: false,
            on: {
                click: (swiperEvent) => this.mySwiper.slideTo(swiperEvent.clickedIndex, 200),
                slideChange: (swiperEvent) => this.setState({ selectedIndex: swiperEvent.realIndex }),
                touchStart: (swiperEvent) => {
                    if (this.mySwiper && this.mySwiper.realIndex !== swiperEvent.realIndex * 2 && this.state.inTransition) {
                        this.mySwiper.slideTo(swiperEvent.realIndex, 200)
                        this.setState({ selectedIndex: swiperEvent.realIndex })
                    }
                },
                slideChangeTransitionStart: () => this.setState({ inTransition: true }),
                slideChangeTransitionEnd: () => this.setState({ inTransition: false }),
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedIndex } = this.state, { onChange } = this.props
        if (selectedIndex !== prevState.selectedIndex && typeof selectedIndex === 'number' && onChange) onChange(this.numbers[selectedIndex])
    }

    render() {
        const { selectedIndex } = this.state
        const selectedNumber = this.numbers[selectedIndex]

        return (
            <StyledNumberSlider id='swiper-container'>
                <div className='swiper-wrapper' tabIndex='0' onWheel={(e) => {
                    if (e.deltaY > 0) { e.key = 'ArrowDown'; this._onKeyPress(e) }
                    else if (e.deltaY < 0) { e.key = 'ArrowUp'; this._onKeyPress(e) }}
                }>
                    {this.numbers.map((number, index) => (
                        <StyledNumber key={index} id={`swiper-slide-${number}`}
                            $classes={utils.createClassName('swiper-slide', {
                                'highlighted': selectedNumber === number,
                                'prev': selectedNumber - number > 0,
                                'next': selectedNumber - number < 0
                            }).split(' ')}
                            className={ utils.createClassName('swiper-slide', {
                                'highlighted': selectedIndex === index,
                                'prev': selectedIndex > index,
                                'next': selectedIndex < index
                            })}>
                            <p>{number}</p>
                        </StyledNumber>
                    ))}
                </div>
            </StyledNumberSlider>
        )
    }

    _onKeyPress = (e) => {
        const { state, props, mySwiper } = this
        const { selectedIndex } = state, { onEnter, onEscape } = props, { key } = e
        const isDecimal = key === 'Decimal' || key === '.' || key === ','
        let currentSelectedIndex = selectedIndex

        if (key === 'ArrowUp' && currentSelectedIndex !== 0) {
            mySwiper.slidePrev(200)
        } else if (key === 'ArrowDown' && this.numbers.length > selectedIndex) {
            mySwiper.slideNext(200)
        } else if (key === " ") {
            //do nothing
        } else if (!isNaN(key) || isDecimal) {
            if (!this._inputNumber) this._inputNumber = ''

            if (this._inputNumber.includes('.')) {
                key <= 2 ? this._inputNumber += ''
                : key > 2 && key < 8 ? this._inputNumber += '5'
                : this._inputNumber = (this.numbers[selectedIndex] + 1) + '.'
            } else {
                this._inputNumber += isDecimal ? '.' : key
            }
            
            if (this._inputNumber > 24) this._inputNumber = props.lastReportedHour > 24 ? props.lastReportedHour.toString() : '24'

            mySwiper.slideTo(parseFloat(this._inputNumber * 2), 200)

            if (this._inputTimeout) clearTimeout(this._inputTimeout)
            this._inputTimeout = setTimeout(() => {
                delete this._inputNumber
                delete this._inputTimeout
            }, 500)
        } else if (key === 'Enter') {
            if (onEnter) onEnter(this.numbers[selectedIndex])
        } else if (key === 'Escape') {
            if(onEscape) onEscape()
        } else if (key === "Unidentified") {
            mySwiper.slideTo(parseFloat(16), 200)
        }
    }
}

const StyledNumberSlider = styled.div`
    // background: ${props => props.theme.background};
    // box-shadow: 0 0 0 2px ${props => props.theme.secondary};
`;

const StyledNumber = styled.div`
    ${props => props.$classes.includes('highlighted') && css`
        color: ${props.theme.primary}
    `}
`